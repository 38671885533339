import type { topicPageQuery$data } from "scmp-app/queries/__generated__/topicPageQuery.graphql";
import type { topicTopicPageTopic$data } from "scmp-app/queries/__generated__/topicTopicPageTopic.graphql";

const chunkArray = <T>(array: T[], hasNextPage = true): T[][] => {
  const pattern = [3, 4, 4]; // Define the repeating pattern
  const result: T[][] = [];

  let index = 0; // Start at the beginning of the array

  while (index < array.length) {
    for (const length of pattern) {
      const chunk = array.slice(index, index + length);
      if (hasNextPage) {
        if (chunk.length === length) result.push(chunk); // Do not push the chunk if items is not enough with the [3, 4, 4] pattern
      } else {
        if (chunk.length > 0) result.push(chunk);
      }

      index += length;
      if (index >= array.length) break;
    }
  }

  return result;
};

const showSubScriptionMessageNumber = 17;

export const parseList = (
  contentList: topicTopicPageTopic$data,
  hasNextPage: boolean,
  hasSponsor: boolean,
  topic: topicPageQuery$data,
  isSuperTopic: boolean,
) => {
  const { superTopicQueue } = topic;

  const contentEdges = contentList?.contents?.edges.map(({ node }) => node) ?? [];
  const superTopicEdges = superTopicQueue?.items?.edges.map(({ node }) => node) ?? [];
  const combinedEdges = isSuperTopic ? [...superTopicEdges, ...contentEdges] : contentEdges;

  const existingEntityUuids = new Set();
  const edges = combinedEdges.filter(edge => {
    const duplicate = existingEntityUuids.has(edge.entityUuid);
    existingEntityUuids.add(edge.entityUuid);
    return !duplicate;
  });

  /** [1st, 2nd, 3rd] */
  const heroContentsGroupSize = 3;
  const heroContentsGroup = edges?.slice(0, heroContentsGroupSize);

  const chunkedArticlesGroup = chunkArray(edges?.slice(heroContentsGroupSize), hasNextPage);

  /** [[4th, 5th, 6th], [7th, 8th, 9th, 10th], [11th, 12th, 13th, 14th] ..., [26th, 27th, 28th]] */
  const topContentsGroupNumber = 7;
  const topContentsComponents = chunkedArticlesGroup
    .slice(0, topContentsGroupNumber)
    .map((group, index) => ({
      content: group,
      type: `TopContents${index + 1}`,
    }));

  /** [[29th, 30th, 31th, 32th], [33th, 34th, 35th, 36th], [37th, 38th, 39th] ...] */
  const restContentsComponents = chunkedArticlesGroup.slice(topContentsGroupNumber);

  return {
    contentItemLength: edges.length,
    heroContentsGroup,
    isShowSubscriptionMessage: edges.length >= showSubScriptionMessageNumber,
    restContentsComponents,
    topContentsComponents,
  };
};
