import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { topicMetadataDisplayTopic$key } from "scmp-app/queries/__generated__/topicMetadataDisplayTopic.graphql";

import {
  Container,
  Divider,
  ExtraMetadataContainer,
  ExtraMetadataDescription,
  ExtraMetadataItem,
  ExtraMetadataRow,
  ExtraMetadataTitle,
  ImageIcon,
  StyledBaseLink,
  StyledEntityLink,
} from "./styles";

type Props = {
  className?: string;
  reference: topicMetadataDisplayTopic$key;
};

export const TopicMetadataDisplay: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const topic = useFragment(
    graphql`
      fragment topicMetadataDisplayTopic on Topic {
        ...hooksTopic
        extraMetadata {
          key
          value
          topic {
            name
            ...entityLink
            types
            urlAlias
            mobileImage: image(type: MOBILE_APP_IMAGE) {
              style(filter: { style: "300x300" }) {
                url
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  return (
    <Container className={className}>
      {topic.extraMetadata && topic.extraMetadata.length > 0 && (
        <>
          <Divider />
          <ExtraMetadataContainer>
            {topic.extraMetadata.map(
              (item, index) =>
                item?.key &&
                item?.value && (
                  <ExtraMetadataItem key={index}>
                    <ExtraMetadataRow>
                      <ExtraMetadataTitle>{item.key}</ExtraMetadataTitle>
                      <ExtraMetadataDescription>
                        {item.value.split(";").map((line, index_) => (
                          <span key={index_}>
                            {index_ > 0 && <br />}
                            {item.topic?.urlAlias ? (
                              <StyledBaseLink pathname={item.topic.urlAlias}>{line}</StyledBaseLink>
                            ) : (
                              line
                            )}
                          </span>
                        ))}
                      </ExtraMetadataDescription>
                    </ExtraMetadataRow>
                    {item.topic?.types?.includes("person") &&
                      item.topic?.mobileImage?.style?.url && (
                        <StyledEntityLink reference={item.topic}>
                          <ImageIcon
                            $aspectRatio="1"
                            alt={item.topic?.name}
                            lazyload={false}
                            src={item.topic?.mobileImage.style.url}
                          />
                        </StyledEntityLink>
                      )}
                  </ExtraMetadataItem>
                ),
            )}
          </ExtraMetadataContainer>
        </>
      )}
    </Container>
  );
};

TopicMetadataDisplay.displayName = "TopicMetadataDisplay";
