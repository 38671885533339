import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { Ads1, Ads2, Ads3, Ads4 } from "scmp-app/pages/topic/styles";
import type { topicAdsTopic$key } from "scmp-app/queries/__generated__/topicAdsTopic.graphql";

import { getTopicAdsConfigs } from "./ads.config";

const adsComponentMap = [Ads1, Ads2, Ads3, Ads4];

export type Props = {
  isShowSpecificWidget: boolean;
  maxAdsNumber: number;
  reference: topicAdsTopic$key;
};
export const TopicAds: FunctionComponent<Props> = ({
  isShowSpecificWidget,
  maxAdsNumber,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment topicAdsTopic on Topic {
        entityId
        advertZone(version: 2)
      }
    `,
    reference_,
  );

  const adsConfigs = getTopicAdsConfigs(
    data?.entityId ?? undefined,
    data?.advertZone ?? undefined,
    isShowSpecificWidget,
  ).slice(0, maxAdsNumber);

  return (
    <>
      {adsConfigs.map(([desktopConfig, mobileConfig], index) => {
        const ComponentType = adsComponentMap[index];

        return (
          <ComponentType key={`AdSlot${index}`}>
            {desktopConfig && <AdSlot {...desktopConfig} />}
            {mobileConfig && <AdSlot {...mobileConfig} />}
          </ComponentType>
        );
      })}
    </>
  );
};

TopicAds.displayName = "TopicAds";
