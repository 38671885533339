/**
 * @generated SignedSource<<c0f8a313c32d6192730d3bf36cc040fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksTopicWidgetQuery$data = {
  readonly appConfig: {
    readonly json: unknown | null | undefined;
  } | null | undefined;
  readonly topic: {
    readonly entityId: string;
    readonly " $fragmentSpreads": FragmentRefs<"hooksTopic" | "topicAdsTopic" | "topicMetadataDisplayTopic">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"chinaScienceDiscoveryWidgetQuery" | "discoveryWidgetPostMagazineQueueQuery" | "hundredTopTablesDiscoveryWidgetQuery" | "openQuestionsSeriesDiscoveryWidgetQuery">;
  readonly " $fragmentType": "hooksTopicWidgetQuery";
};
export type hooksTopicWidgetQuery$key = {
  readonly " $data"?: hooksTopicWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksTopicWidgetQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksTopicWidgetQuery"
};

(node as any).hash = "35b506352dbd544b1cb36fd5111e0e80";

export default node;
