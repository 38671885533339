export const TopicIdsShowing100TopTablesDiscoveryWidget = new Set([
  "34148",
  "308301",
  "317874",
  "318446",
  "319187",
  "321063",
  "321409",
  "322197",
  "322439",
  "322873",
  "322874",
  "322875",
  "323374",
  "323549",
  "323550",
  "323551",
  "323552",
  "323553",
  "323554",
  "323555",
  "323719",
  "323961",
  "323962",
  "323963",
  "323964",
  "323965",
  "323966",
  "323967",
  "323992",
  "324008",
  "324465",
  "324801",
  "325538",
  "325653",
  "325972",
  "326828",
  "326975",
  "327148",
  "327356",
  "327360",
  "328247",
  "328529",
  "328983",
  "328985",
  "329134",
  "329812",
  "330311",
  "330313",
  "330314",
  "330316",
  "330378",
  "330379",
  "330393",
  "330396",
  "330425",
  "330431",
  "330456",
  "330484",
  "330578",
  "330654",
  "330666",
  "330667",
  "330705",
  "330751",
  "330756",
  "330823",
  "331074",
  "500028",
  "500031",
  "500096",
  "500105",
  "500115",
  "500124",
  "500129",
  "500130",
  "500142",
  "500143",
  "500169",
  "500188",
  "500189",
  "500190",
  "500191",
  "500198",
  "500200",
  "500217",
  "501840",
  "502339",
  "502441",
  "502559",
  "502640",
  "502714",
  "502776",
  "502885",
  "502896",
  "502961",
  "503108",
  "503174",
  "503260",
  "503351",
  "503368",
  "503612",
  "503755",
  "503892",
  "505230",
  "505298",
  "505446",
  "505518",
  "505607",
  "505700",
  "505770",
  "505870",
  "506021",
  "506113",
  "506186",
  "506267",
  "506344",
  "506434",
  "506487",
  "506602",
  "507054",
  "507115",
  "507215",
  "507545",
  "507871",
  "508005",
  "508168",
  "508360",
  "508674",
  "508738",
  "509034",
  "509093",
  "509183",
  "509184",
  "509329",
  "509442",
  "509595",
  "509763",
  "509772",
  "509791",
  "510419",
  "512161",
  "512167",
  "512169",
  "512170",
  "512171",
  "512172",
  "512173",
  "512174",
  "512729",
  "515270",
  "515894",
  "515899",
  "515974",
  "516963",
  "517180",
  "517851",
]);
